<template>
<div class="relative h-screen bg-gray-900 flex flex-col" :style="{
    backgroundImage: 'url(/img/throwbit_bot_background.svg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }">

    <Header />
    <!-- Notification -->
    <div v-if="notification" class="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-600 text-white p-4 rounded-lg shadow-lg transition-all duration-300 ease-in-out z-50 max-w-md w-full flex items-center">
        <i class="fas fa-check-circle text-2xl mr-2"></i>
        <p class="text-center">{{ notification }}</p>
    </div>

    <div class="flex-1 flex flex-col items-center justify-center p-4 mb-20">
        <img src="/img/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110 cursor-pointer shadow-lg animate-spin" ref="planetImage" />
        <div class="w-full max-w-2xl rounded-2xl overflow-hidden shadow-lg">
            <ul class="space-y-4">
                <li v-for="airdrop in airdrops" :key="airdrop.id" class="bg-gray-800 rounded-2xl p-4 shadow-lg flex justify-between items-center">
                    <div>
                        <h2 class="text-lg font-semibold text-white">{{ airdrop.title }}</h2>
                        <span class="text-green-500 font-bold">{{ Math.round(airdrop.amount) }} TH</span>
                    </div>
                    <button @click="claimAirdrop(airdrop.id)" class="px-4 py-2 bg-blue-500 rounded-full hover:bg-blue-400 transition duration-300" :disabled="airdrop.claimed">
                        {{ airdrop.claimed ? 'Claimed' : 'Claim' }}
                    </button>
                </li>
            </ul>
        </div>
    </div>

    <!-- Navigation Menu -->
    <nav class="fixed bottom-4 left-0 right-0 bg-[#rgb(65 145 150)] p-3 shadow-lg rounded-t-lg mx-auto" style="width: 90%;">
        <ul class="flex justify-around items-center space-x-4">
            <li class="flex flex-col items-center">
                <a href="/start" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white" :class="{ 'bg-blue-700': isActive('home') }">
                    <i class="fas fa-home text-xl text-white"></i>
                    <span class="text-sm text-white">Home</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/referal" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                    <i class="fas fa-user-friends text-xl text-white"></i>
                    <span class="text-sm text-white">Friends</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/task" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                    <i class="fas fa-tasks text-xl text-white"></i>
                    <span class="text-sm text-white">Tasks</span>
                </a>
            </li>
            <li class="flex flex-col items-center">
                <a href="/airdrop" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                    <i class="fas fa-gift text-xl text-white"></i>
                    <span class="text-sm text-white">Airdrop</span>
                    <span v-if="isActive('airdrop')" class="absolute inset-0 border-2 border-blue-400 rounded-full"></span>
                </a>
            </li>
        </ul>
    </nav>

</div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
    components: {
        Header
    },
    data() {
        return {
            airdrops: [{
                    id: 1,
                    title: 'Level 1',
                    amount: 10000,
                    claimed: false
                },
                {
                    id: 2,
                    title: 'Level 2',
                    amount: 50000,
                    claimed: false
                },
                {
                    id: 3,
                    title: 'Level 3',
                    amount: 1000000,
                    claimed: true
                },
            ],
            notification: ''
        };
    },
    methods: {
        claimAirdrop(airdropId) {
            const airdrop = this.airdrops.find(a => a.id === airdropId);
            if (airdrop && !airdrop.claimed) {
                airdrop.claimed = true;
                this.showNotification(`${airdrop.amount} TH claimed`);
            }
        },
        showNotification(message) {
            this.notification = message;
            setTimeout(() => {
                this.notification = '';
            }, 3000);
        },
        isActive(page) {
            return page === 'airdrop';
        },
    },
};
</script>

<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
</style>
