<template>
<!-- Header section with "Cancel" and the three-dot button -->
<div class="absolute top-0 left-0 right-0 flex justify-between items-center p-6">
    <a href="/" class="text-white text-lg font-semibold hover:text-gray-300 transition duration-300">Cancel</a>
    <div class="text-center text-lg font-semibold text-white">
        ThrowCoin <span class="text-sm block font-normal">bot</span>
    </div>
    <button class="text-white p-2 rounded-full hover:bg-gray-800 transition duration-300">
        <i class="fas fa-ellipsis-h text-2xl"></i>
    </button>
</div>
</template>
    
<script>
export default {

}
</script>
    
    
<style scoped>
    
    </style>
