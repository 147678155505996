<template>
<div class="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32" :style="{
    backgroundImage: 'url(/img/throwbit_bot_background.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }">
    <br>
    <img src="/img/planete-1.svg" alt="Planet Background" class="absolute inset-0 -z-10 w-1/2 max-w-md h-auto object-cover object-center mx-auto animate-pulse"> <br><br><br><br>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:mx-0 text-center lg:text-left">
            <h2 class="text-4xl font-extrabold tracking-tight text-white sm:text-6xl drop-shadow-lg font-[Poppins]">Welcome to Throwbit space🚀</h2>
            <p class="mt-6 text-lg leading-8 text-gray-300 drop-shadow-md tracking-wide font-[Lora]">Tap on the planets to start your adventure! Compete with friends and reach for the stars in this exciting game.</p>
        </div>
        <div class="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">
            <div class="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-white sm:grid-cols-2 md:flex lg:gap-x-10">
                <a href="/start" class="flex items-center justify-center px-6 py-3 bg-purple-600 rounded-full shadow-md hover:bg-purple-500 transition duration-300">
                    <i class="fas fa-play mr-2"></i> Start Playing <span aria-hidden="true">&rarr;</span>
                </a>
                <a href="/game-rules" class="flex items-center justify-center px-6 py-3 bg-teal-600 rounded-full shadow-md hover:bg-teal-500 transition duration-300">
                    <i class="fas fa-book mr-2"></i> Game Rules <span aria-hidden="true">&rarr;</span>
                </a>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from "@/api/axios.js";

export default {
    data() {
        return {

        };
    },
    methods: {

    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400&family=Poppins:wght@600;800&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
</style>
