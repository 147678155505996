<template>
    <div class="relative h-screen bg-gray-900 flex flex-col" :style="{
          backgroundImage: 'url(/img/throwbit_bot_background.svg)',
              backgroundSize: 'cover',
              backgroundPosition: 'center'
            }">
      <Header />
      <div class="flex-1 flex flex-col items-center justify-center p-4">
        <img src="/img/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110 cursor-pointer animate-pulse" ref="planetImage" />
  
        <!-- Task list -->
        <div class="w-full max-w-2xl">
          <ul class="space-y-4">
            <li class="bg-gray-800 rounded-full p-4 flex justify-between items-center shadow-lg">
              <a href="https://twitter.com/votrecompte" target="_blank" class="flex items-center w-full">
                <img src="/img/icons/twitter.png" alt="Twitter" class="w-6 h-6 mr-4" />
                <span class="text-white">Follow le compte Twitter</span>
              </a>
            </li>
            <li class="bg-gray-800 rounded-full p-4 flex justify-between items-center shadow-lg">
              <a href="https://t.me/votrecompte" target="_blank" class="flex items-center w-full">
                <img src="/img/icons/telegram.svg" alt="Telegram" class="w-6 h-6 mr-4" />
                <span class="text-white">S'abonner à Telegram</span>
              </a>
            </li>
            <li class="bg-gray-800 rounded-full p-4 flex justify-between items-center shadow-lg">
              <a href="#" @click.prevent="toggleEmailInput" class="flex items-center w-full cursor-pointer">
                <img src="/img/icons/google.png" alt="Email" class="w-6 h-6 mr-4" />
                <span class="text-white">Confirme ton e-mail</span>
              </a>
            </li>
          </ul>
  
          <!-- Input field for email, shown when "Confirme ton e-mail" is clicked -->
          <div v-if="showEmailInput" class="mt-4 relative max-w-md">
            <input type="email" v-model="email" placeholder="Enter your email" class="p-3 w-full bg-gray-700 text-white rounded-lg pr-12">
            <span @click="submitEmail" class="absolute inset-y-0 right-3 flex items-center cursor-pointer">
              <i class="fas fa-paper-plane text-blue-500 hover:text-blue-700"></i>
            </span>
          </div>
        </div>
      </div>
  
      <!-- Navigation Menu -->
      <nav class="fixed bottom-4 left-0 right-0 bg-[#rgb(65 145 168)] p-3 shadow-lg rounded-t-lg mx-auto" style="width: 90%;">
        <ul class="flex justify-around items-center space-x-4">
          <li class="flex flex-col items-center">
            <a href="/start" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white" :class="{ 'bg-blue-700': isActive('home') }">
              <i class="fas fa-home text-xl text-white"></i>
              <span class="text-sm text-white">Home</span>
            </a>
          </li>
          <li class="flex flex-col items-center">
            <a href="/referal" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
              <i class="fas fa-user-friends text-xl text-white"></i>
              <span class="text-sm text-white">Friends</span>
            </a>
          </li>
          <li class="flex flex-col items-center">
            <a href="/task" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
              <i class="fas fa-tasks text-xl text-white"></i>
              <span class="text-sm text-white">Tasks</span>
              <span v-if="isActive('tasks')" class="absolute inset-0 border-2 border-blue-400 rounded-full"></span>
            </a>
          </li>
          <li class="flex flex-col items-center">
            <a href="/airdrop" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
              <i class="fas fa-gift text-xl text-white"></i>
              <span class="text-sm text-white">Airdrop</span>
            </a>
          </li>
        </ul>
      </nav>
  
    </div>
  </template>
  
  <script>
  import Header from "@/components/Header.vue";
  export default {
    components: {
      Header,
    },
    data() {
      return {
        showEmailInput: false, 
        email: '',             
      };
    },
    methods: {
      isActive(page) {
        return page === 'tasks';
      },
      toggleEmailInput() {
        this.showEmailInput = !this.showEmailInput;
      },
      submitEmail() {
        if (this.email) {
          alert(`Email submitted: ${this.email}`);
          this.showEmailInput = false;
        } else {
          alert('Please enter a valid email.');
        }
      },
    },
  };
  </script>
  
  <style scoped>
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
  </style>
  