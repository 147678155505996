<template>
  <div class="relative h-screen bg-gray-900 flex flex-col" :style="{
      backgroundImage: 'url(/img/throwbit_bot_background.svg)',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }">
      <Header />
  
      <div class="flex-1 flex flex-col items-center justify-center p-4">
          <img src="/img/planete-2.svg" alt="Planet" class="w-48 h-48 md:w-72 md:h-72 mx-auto transform transition-transform duration-300 hover:scale-110 cursor-pointer animate-bounce" ref="planetImage" />
  
          <!-- Referral Link Section -->
          <div class="w-full max-w-2xl text-center my-4">
              <p class="text-white">Your Referral Link:</p>
              <div class="flex justify-center items-center mt-2">
                  <input type="text" v-model="referralLink" readonly class="p-2 bg-gray-700 text-white rounded-lg mr-2 w-full max-w-xs" />
                  <button @click="copyReferralLink" class="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-400 transition duration-300"> <i class="fas fa-copy text-xl"></i></button>
              </div>
              <!-- Notification -->
              <p v-if="copySuccess" class="text-green-500 mt-2">Referral link copied!</p>
          </div>
  
          <!-- Friends List -->
          <div class="w-full max-w-2xl">
              <ul class="space-y-2">
                  <li v-for="friend in friends" :key="friend.id" class="bg-gray-800 rounded-full p-2 flex justify-between items-center shadow-md">
                      <div class="flex items-center">
                          <img :src="friend.avatar" alt="Avatar" class="w-12 h-12 rounded-full mr-3" />
                          <div>
                              <h2 class="text-sm text-white">{{ friend.name }}</h2>
                              <p class="text-xs text-gray-400">Points: {{ friend.points }}</p>
                          </div>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  
      <!-- Navigation Menu -->
      <nav class="fixed bottom-4 left-0 right-0 bg-[#rgb(65 145 168)] p-3 shadow-lg rounded-t-lg mx-auto" style="width: 90%;">
          <ul class="flex justify-around items-center space-x-4">
              <li class="flex flex-col items-center">
                  <a href="/start" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white" :class="{ 'bg-blue-700': isActive('home') }">
                      <i class="fas fa-home text-xl text-white"></i>
                      <span class="text-sm text-white">Home</span>
                  </a>
              </li>
              <li class="flex flex-col items-center">
                  <a href="/referal" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                      <i class="fas fa-user-friends text-xl text-white"></i>
                      <span class="text-sm text-white">Friends</span>
                      <span v-if="isActive('referal')" class="absolute inset-0 border-2 border-blue-400 rounded-full"></span>
                  </a>
              </li>
              <li class="flex flex-col items-center">
                  <a href="/task" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                      <i class="fas fa-tasks text-xl text-white"></i>
                      <span class="text-sm text-white">Tasks</span>
                      <span v-if="isActive('tasks')" class="absolute inset-0 border-2 border-blue-400 rounded-full"></span>
                  </a>
              </li>
              <li class="flex flex-col items-center">
                  <a href="/airdrop" class="flex flex-col items-center p-3 rounded-full shadow-lg transition-transform duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/50 relative text-white">
                      <i class="fas fa-gift text-xl text-white"></i>
                      <span class="text-sm text-white">Airdrop</span>
                  </a>
              </li>
          </ul>
      </nav>
  
  </div>
  </template>
  
  <script>
  import Header from "@/components/Header.vue";
  export default {
      components: {
          Header
      },
      data() {
          return {
              friends: [{
                      id: 1,
                      name: 'John Doe',
                      points: 120,
                      avatar: '/img/planete-2.svg',
                  },
                  {
                      id: 2,
                      name: 'Marie Curie',
                      points: 200,
                      avatar: '/img/planete-2.svg',
                  },
                  {
                      id: 3,
                      name: 'Pierre Martin',
                      points: 75,
                      avatar: '/img/planete-2.svg',
                  },
              ],
              referralLink: 'https://example.com/refer?code=YOUR_REFERRAL_CODE',
              copySuccess: false,
          };
      },
      methods: {
          copyReferralLink() {
              navigator.clipboard.writeText(this.referralLink).then(() => {
                  this.copySuccess = true;
                  setTimeout(() => {
                      this.copySuccess = false;
                  }, 1000);
              });
          },
          isActive(page) {
              return page === 'referal';
          },
      },
  };
  </script>
  
<style scoped>
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
</style>
